// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-earlier-work-mdx": () => import("./../../../src/pages/career/earlier-work.mdx" /* webpackChunkName: "component---src-pages-career-earlier-work-mdx" */),
  "component---src-pages-career-fleek-mdx": () => import("./../../../src/pages/career/fleek.mdx" /* webpackChunkName: "component---src-pages-career-fleek-mdx" */),
  "component---src-pages-career-index-mdx": () => import("./../../../src/pages/career/index.mdx" /* webpackChunkName: "component---src-pages-career-index-mdx" */),
  "component---src-pages-career-justo-mdx": () => import("./../../../src/pages/career/justo.mdx" /* webpackChunkName: "component---src-pages-career-justo-mdx" */),
  "component---src-pages-career-papinotas-mdx": () => import("./../../../src/pages/career/papinotas.mdx" /* webpackChunkName: "component---src-pages-career-papinotas-mdx" */),
  "component---src-pages-career-tiendapp-mdx": () => import("./../../../src/pages/career/tiendapp.mdx" /* webpackChunkName: "component---src-pages-career-tiendapp-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

